<template>
  <div class="fs-container">
    <div class="fs-section fs-article">
      <div class="banner-box">
        <div class="fs-pic banner-pic">
          <img :src="CampaignDetail.BannerImage" :alt="CampaignDetail.Title" />
        </div>
        <div class="banner-info">
          <h1 v-html="CampaignDetail.Title"></h1>
          <p>
            <strong
              ><span class="color-red">{{ CampaignDetail.Tagging }}</span> NEWS
              {{ CampaignDetail.month_ }}</strong
            >
            {{ CampaignDetail.days }}
            DAYS AGO
          </p>
        </div>
      </div>

      <div class="fs-inr article-inr">
        <div class="acticle-detail">
          <div class="acticle-top">
            <div class="user-box">
              <div class="user-pic">
                <img
                  :src="CampaignDetail.AuthorIcon"
                  :alt="CampaignDetail.Author"
                />
              </div>
              <div class="user-txt">{{ CampaignDetail.Author }}</div>
            </div>
            <div class="social">
              <div class="social-inr">
                <div
                  class="fb-like"
                  :data-href="currentUrl"
                  data-width=""
                  data-layout="button"
                  data-action="like"
                  data-size="large"
                  data-share="true"
                ></div>
              </div>
            </div>
          </div>

          <div class="article-content">
            <img
              v-if="CampaignDetail.DetailImage"
              :src="CampaignDetail.DetailImage"
              :alt="CampaignDetail.Title"
            />
            <div class="detail-wrap" v-html="CampaignDetail.Detail"></div>
          </div>
          <div class="social-box">
            <div class="social-inr">
              <div
                class="fb-like"
                :data-href="currentUrl"
                data-width=""
                data-layout="button"
                data-action="like"
                data-size="large"
                data-share="true"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      CampaignDetail: {},
      videoId: "",
      modalClass: "",
      Slug: "",
      currentUrl: "",
    };
  },
  watch: {
    $route(to, from) {
      this.fetch();
    },
  },
  created() {
    this.currentUrl = window.location.href;
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    console.log(this.$route.params.Slug);
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    fetch() {
      this.loading(true);
      useJwt
        .post("getMasterData/CampaignDetail", {
          columnFilters: { Slug: this.$route.params.Slug },
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.CampaignDetail = response.data.result[0];
          console.log(this.CampaignDetail);
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
        "https://www.youtube.com/embed/" +
        url +
        "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector(".fs-header").clientHeight;
      [].slice
        .call(
          document.querySelectorAll(
            ".fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr"
          )
        )
        .forEach((elm) => {
          const style = window.getComputedStyle(elm, null);
          const elmH = style.getPropertyValue("height");
          const inputT = windowH - elm.getBoundingClientRect().top;
          if (inputT > 0 && inputT < windowH + parseInt(elmH, 10) - headH / 2) {
            elm.classList.add("fs-ani");
          } else {
            elm.classList.remove("fs-ani");
          }
        });
    },
  },
};
</script>